<template>
  <div class="flex flex-col bg-lightestGray p-4">
    <div class="flex items-center font-semibold text-darkGray mb-6 px-4">
      <Button
        class="ml-auto px-2"
        :class="{ 'invisible': !canUpdate, 'hover:text-primary': isUpdatable }"
        variant="noBorder"
        icon="reset"
        :disabled="!isUpdatable"
        @click="updateQuestionnaireDialog = true"
      >
        {{ $t('general.update') }}
      </Button>

      <Button
        class="ml-6 px-2"
        :class="isDeletable && 'hover:text-primary'"
        variant="noBorder"
        icon="trash"
        :disabled="!isDeletable"
        @click="deleteQuestionnaireDialog = true"
      >
        {{ $t('general.delete') }}
      </Button>

      <span
        class="inline-flex items-center hover:text-primary hover:cursor-pointer ml-6"
      >
        <Icon name="magnifier" class="flex-shrink-0 w-7" />
        <router-link
          class="ml-2"
          :to="{ name: 'answerQuestionnaire', params: { questionnaireId } }"
        >
          {{ $t('score.questionnaire_show') }}
        </router-link>
      </span>
    </div>

    <div v-if="questionnaire?.modifiedBy">
      <h2 class="py-[15px] px-4 text-lightBlack">
        {{
          $t('assetManagement.questionnaire-lastChange', {
            date: formatDate(questionnaire?.modifiedDate),
            user:
              questionnaire?.modifiedBy?.firstName +
              ' ' +
              questionnaire?.modifiedBy?.lastName,
          })
        }}
      </h2>
    </div>

    <div class="flex font-semibold text-darkGray mb-6 px-4">
      <router-link
        v-for="{ routeName, label } in tabs"
        :key="label"
        class="inline-flex hover:text-primary mx-8 first:mx-0"
        :class="$route.name === routeName && 'text-primary'"
        :to="{ name: routeName }"
      >
        {{ label }}
      </router-link>
    </div>
    <NotificationBar
      :text="
        $t(
          canUpdate
            ? 'score.questionnaire-update-notification-objectadministrator'
            : 'score.questionnaire-update-notification-objectdatacollector'
        )
      "
      :color="'orange'"
      v-show="isUpdatable"
    />
    <QuestionnaireScoreInfo v-bind="{ assetId, questionnaireId ,isBuyerAsset}" />

    <DeleteAssetQuestionnaireDialog
      v-model="deleteQuestionnaireDialog"
      v-bind="{ title, assetId, questionnaireId,isBuyerAsset }"
      @delete="questionnaireDeleted"
    />

    <UpdateToActiveQuestionnaireDialog
      v-model="updateQuestionnaireDialog"
      :assetId="assetId"
      :isBuyerAsset="isBuyerAsset"
      @update="questionnaireUpdated"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EAssetState, EQuestionnaireState } from '@/enums'
import Icon from '@/components/Icon'
import { Button } from '@/components/form'
import {
  DeleteAssetQuestionnaireDialog,
  UpdateToActiveQuestionnaireDialog,
} from '@/components/dialog'
import { QuestionnaireScoreInfo } from '@/components/questionnaire/score'
import { user, general } from '@/helpers'
import { EUserRole } from '@/enums'
import NotificationBar from '@/components/NotificationBar.vue'
import { useRequest } from '@/composition'
import { questionnaires as questionnairesApi } from '@/services/api'

export default {
  components: {
    Icon,
    Button,
    DeleteAssetQuestionnaireDialog,
    UpdateToActiveQuestionnaireDialog,
    QuestionnaireScoreInfo,
    NotificationBar,
  },
  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },
  inject: ['deletedQuestId'],
  data() {
    return {
      deleteQuestionnaireDialog: false,
      updateQuestionnaireDialog: false,
      questionnaire: null,
    }
  },
  setup(props) {
    const questionnaireRequest = useRequest(() =>
      questionnairesApi.getAssetQuest(props.assetId, props.questionnaireId,props.isBuyerAsset)
    )
    return {
      questionnaireRequest,
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['title', 'assetState', 'state', 'id', 'canBeUpdatedToActiveQuestionnaire']),
    isDeletable() {
      return (this.assetState === EAssetState.Active) && !this.isOAofFinanceOrg
    },
    isUpdatable() {
      return (
        this.id === parseInt(this.questionnaireId, 10) &&
        this.assetState === EAssetState.Active &&
        this.state === EQuestionnaireState.Archived &&
        this.canBeUpdatedToActiveQuestionnaire
      )
    },
    canUpdate() {
      return user.hasPermission([EUserRole.ObjectAdministrator,EUserRole.ProspectiveBuyer]) && !this.isOAofFinanceOrg
    },
    tabs() {
      return [
        {
          routeName: 'questionnaireResultOverview',
          label: this.$t('assetManagement.resultOverview-tab'),
        },
        {
          routeName: 'questionnaireResultDetails',
          label: this.$t('assetManagement.resultDetail-tab'),
        },
      ]
    },
    isOAofFinanceOrg(){
      return user.hasPermission([EUserRole.ObjectAdministrator]) && this.$store.getters['auth/organisationIsFinancePartner']
    },
  },
  methods: {
    async questionnaireDeleted(id) {
      // wait until route change done so breadcrumbs calculation doesn't error:
      // otherwise it tries to find the selected questionnaire via route params
      await this.$router.replace({ name: 'editObjectData' })
      this.$nextTick(() => {
        this.deletedQuestId.value = id
      })
    },
    async questionnaireUpdated() {
      this.$router.push({
        name: 'editObject',
        params: { assetId: this.assetId },
      })
    },
    ...mapActions('toastMessage', ['showMessage']),
    async fetchQuestionnaire() {
      try {
        this.questionnaire = await this.questionnaireRequest.request()
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-list-fetch-error',
        })
        throw error
      }
    },
    formatDate(dateString) {
      return dateString != null ? general.formatDate(dateString) : ''
    },
  },
  created() {
    this.fetchQuestionnaire()
  },
}
</script>
