<template>
  <div>
    <AnswerCardQuestion
      class="my-4"
      :class="[
        answerRequest.pending && 'cursor-wait',
        questionData.taxonomyRelevant ? 'text-cyan' : 'text-darkGray',
      ]"
      :level="EQuestionnaireLevel.Question"
      :title="defaultingLangEntry.title"
      :score="score"
      :taxonomy="questionData.taxonomyRelevant"
      :disabled="
        !isAnswerable ||
        answerRequest.pending ||
        (questionData.type === EQuestionType.VirtualQuestion &&
          questionData.parentQuestionId)
      "
      :disabledBack="isFirstQuestion"
      :disabledNext="isFinalQuestion"
      :questionNav="userCanAnswer"
      @confirm="save"
      @next="nextQuestion"
      @back="backQuestion"
    >
      <template #content>
        <div class="flex items-center text-darkGray my-4">
          <span
            class="flex-shrink-0 h-2 w-2 mr-2"
            :class="questionData.taxonomyRelevant ? 'bg-cyan' : 'bg-primary'"
          />
          <span class="whitespace-pre-wrap">{{ defaultingLangEntry.description }}</span>
        </div>

        <InfoText class="mt-8 mb-3 whitespace-pre-wrap">
          <p>{{ defaultingLangEntry.clarification }}</p>
          <p class="mt-3">{{ questionTypeInfo }}</p>
        </InfoText>

        <div
          v-if="questionData.type === EQuestionType.SingleChoice"
          class="flex flex-col items-start py-4"
        >
          <div class="flex flex-col">
            <BorderedRadio
              v-for="(option, idx) in questionData.options"
              :key="option"
              class="min-w-[320px] my-3"
              v-model="answerInputs.multipleChoiceOption[0]"
              :value="idx"
              :label="defaultingLangOptions(option)"
              :taxonomy="questionData.taxonomyRelevant"
              :disabled="!isAnswerable"
            />
          </div>
        </div>
        <div
          v-else-if="questionData.type === EQuestionType.MultipleChoice"
          class="flex flex-col items-start py-4"
        >
          <div class="flex flex-col">
            <BorderedCheckbox
              v-for="(option, idx) in questionData.options"
              :key="option"
              class="min-w-[320px] my-3"
              v-model="answerInputs.multipleChoiceOption"
              :value="idx"
              :label="defaultingLangOptions(option)"
              :taxonomy="questionData.taxonomyRelevant"
              :disabled="
                !isAnswerable || isNonExclusiveMultipleChoiceAnswer(idx)
              "
            />
          </div>
        </div>
        <div v-else-if="questionData.type === EQuestionType.ValueQuestion" class="pt-1">
          <Textarea
            v-model="answerInputs.inputAnswer"
            :placeholder="$t('score.record-valueQuestionTextInput')"
            :visibleHeader="false"
            :disabled="!isAnswerable"
          />
        </div>
        <div
          v-else-if="questionData.type === EQuestionType.VirtualQuestion"
          class="flex flex-col items-start py-1"
        >
          <div class="flex">
            <component
              :is="noStatement ? 'Input' : 'FloatInput'"
              v-if="showInput"
              class="w-[30%] mr-3"
              v-model="inputModel"
              :placeholder="
                noStatement ? '—' : $t('score.record-valueQuestionTextInput')
              "
              :visibleHeader="false"
              :disabled="!isAnswerable || noStatement"
            />
            <div
              class="min-w-[320px] ml-3 my-7 text-primary border-primary border-[2.5px] rounded-3xl font-semibold bg-ultralightGray focus:outline-none py-[9px] px-[25px]"
            >{{ percentageDeviation }}</div>
          </div>

          <Checkbox
            v-if="showInput"
            class="text-darkGray ml-2"
            :modelValue="noStatement"
            :disabled="!isAnswerable"
            @update:modelValue="onNoStatementSelect"
          >{{ $t('score.question-virtualQuestionType-all-noStatement') }}</Checkbox>
        </div>
      </template>
    </AnswerCardQuestion>

    <FileUploadCard
      v-if="getAnswer?.id"
      :answer="getAnswer"
      :canManageFile="userCanManageFile"
      :retrievalConfig="retrievalConfig"
      @fileUploaded="fileUploadedCallback"
    />

    <CommentBox
      v-if="showComments"
      :comments="comments"
      @postComment="postComment"
      :disabled="
        commentRequest.pending || getAnswer.isAnsweredFromReferencedAsset
      "
    />

    <Button
      v-if="canSeeChangeLog"
      class="px-4 ml-4 my-4 focus:bg-transparent active:bg-transparent"
      :class="!showChangeLog && 'active:text-darkGray'"
      :variant="changeLogButtonVariant"
      :disabled="!hasChangeLog"
      icon="change-logs"
      @click="toggleChangeLogs"
    >{{ $t('score.question-changelog') }}</Button>
    <div
      v-show="showChangeLog"
      v-for="entry in questionFromStore.answerChangeLog"
      :key="entry.id"
      class="ml-16"
    >
      <div class="py-1">
        <span v-if="entry.modifiedType === EAnswerChangeLogType.Answered">
          {{
          $t('score.question-changelog-first-answered', {
          name: changeLogUserName(entry),
          questionname: defaultingLangEntry.title,
          date: formatDate(entry?.modifiedDate),
          })
          }}
        </span>
        <span v-if="entry.modifiedType === EAnswerChangeLogType.ModifiedAnswer">
          {{
          $t('score.question-changelog-last-modified', {
          name: changeLogUserName(entry),
          questionname: defaultingLangEntry.title,
          date: formatDate(entry?.modifiedDate),
          })
          }}
        </span>
        <span
          v-if="
            entry.modifiedType ===
            EAnswerChangeLogType.ModifiedQuestionnaireVersion
          "
        >
          {{
          $t('score.question-changelog-questionnaire-updated', {
          name: changeLogUserName(entry),
          templatename: entry.updatedToQuestionnaireName,
          date: formatDate(entry?.modifiedDate),
          })
          }}
        </span>
        <span v-if="entry.modifiedType === EAnswerChangeLogType.RemovedAnswer">
          {{
          $t('score.question-changelog-removed', {
          name: changeLogUserName(entry),
          questionname: defaultingLangEntry.title,
          date: formatDate(entry?.modifiedDate),
          })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil, pick, cloneDeep, debounce, sumBy, round } from 'lodash-es'
import { reactive } from 'vue'
import { useStore, mapActions, mapGetters, mapMutations } from 'vuex'
import {
  question as questionHelpers,
  user as userHelpers,
  general as generalHelper,
} from '@/helpers'
import { useRequest } from '@/composition'
import {
  EUserRole,
  EClusterType,
  EQuestionType,
  EQuestionnaireLevel,
  EVirtQuestType,
  EAssetState,
  EAnswerState,
  EAnswerChangeLogType,
} from '@/enums'
import { CommentBox } from '@/components/questionnaire'
import {
  AnswerCardQuestion,
  FileUploadCard,
} from '@/components/questionnaire/cards'
import {
  InfoText,
  Input,
  FloatInput,
  Textarea,
  BorderedRadio,
  BorderedCheckbox,
  Checkbox,
  Button,
} from '@/components/form'

export default {
  components: {
    AnswerCardQuestion,
    FileUploadCard,
    InfoText,
    Input,
    FloatInput,
    Textarea,
    BorderedRadio,
    BorderedCheckbox,
    Checkbox,
    CommentBox,
    Button,
  },
  props: {
    assetId: {
      // asset id
      type: String,
      required: true,
    },
    clusterNum: {
      type: String,
      required: true,
      validator: val => EClusterType[val] !== undefined,
    },
    categoryId: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const answerRequest = useRequest((retrievalConfig, assetId, isBuyerAsset) =>
      store.dispatch('questionnaire/saveAnswer', {
        retrievalConfig,
        assetId,
        isBuyerAsset,
      })
    )
    const commentRequest = useRequest((retrievalConfig, comment) =>
      store.dispatch('questionnaire/addComment', { retrievalConfig, comment })
    )
    return reactive({
      answerRequest,
      commentRequest,
    })
  },
  data() {
    return {
      questionData: {
        type: null, // EQuestionType
        options: [],
        virtScores: [],
        virtualQuestionType: null, // EVirtQuestType
        parentQuestionId: null,
        taxonomyRelevant: false,
        translations: [
          {
            id: null,
            language: '',
            title: '',
            description: '',
            clarification: '',
          },
        ],
        modified: false,
        answerable: false,
      },
      answerInputs: cloneDeep(this.emptyAnswer),
      noStatement: false,
      preventStoreUpdate: true,
      showChangeLog: false,
      EQuestionnaireLevel,
      EQuestionType,
      EAnswerChangeLogType,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'getQuestion',
      'assetState',
      'lockedStatus',
      'clusters',
      'getClustersIdentifierArray',
    ]),
    assetIdInt() {
      return parseInt(this.assetId)
    },
    clusterIdx() {
      return parseInt(this.clusterNum)
    },
    categoryIdInt() {
      return parseInt(this.categoryId)
    },
    sectionIdInt() {
      return parseInt(this.sectionId)
    },
    questionIdInt() {
      return parseInt(this.questionId)
    },
    retrievalConfig() {
      return {
        clusterIdx: this.clusterIdx,
        categoryId: this.categoryIdInt,
        sectionId: this.sectionIdInt,
        questionId: this.questionIdInt,
      }
    },
    emptyAnswer() {
      return {
        id: null,
        questionId: this.questionIdInt,
        assetId: this.assetId,
        multipleChoiceOption: [],
        inputAnswer: '0',
        comments: [],
        state: null, // EAnswerState
        tableResult: null,
        resultDeviation: null,
        reachedScore: null,
        yearOfLeaving: null,
      }
    },
    userCanAnswer() {
      return userHelpers.hasPermission([
        EUserRole.ObjectDataCollector,
        EUserRole.ProspectiveBuyer,
      ])
    },
    userCanManageFile() {
      return (
        userHelpers.hasPermission([
          EUserRole.ObjectDataCollector,
          EUserRole.ProspectiveBuyer,
        ]) &&
        [
          EAssetState.Verification,
          EAssetState.Active,
          EAssetState.Declined,
        ].includes(this.assetState) &&
        !this.getAnswer.isAnsweredFromReferencedAsset
      )
    },
    isAnswerable() {
      return (
        this.userCanAnswer &&
        this.questionData.answerable &&
        [EAssetState.Active, EAssetState.Declined].includes(this.assetState) &&
        !this.lockedStatus &&
        !this.getAnswer?.isAnsweredFromReferencedAsset
      )
    },
    notValidNumber() {
      return !this.noStatement && this.inputModel > 1000000000000000 // BMC-1447 - Max float number is 340282346638528859811704183484516925440, which is used in the backend. Using a lower value because FE does not recognize higher values as number.
    },
    isFinalQuestion() {
      return (
        this.clusterIdx ===
          this.getClustersIdentifierArray[
            this.getClustersIdentifierArray.length - 1
          ] &&
        this.isLastCategory &&
        this.isLastSection &&
        this.isLastQuestion
      )
    },
    isLastCategory() {
      return (
        this.categoryIdx ===
        this.clusters[this.clusterIdx].categories.length - 1
      )
    },
    isLastSection() {
      return (
        this.sectionIdx ===
        this.clusters[this.clusterIdx].categories[this.categoryIdx].sections
          .length -
          1
      )
    },
    isLastQuestion() {
      return (
        this.questionIdx ===
        this.clusters[this.clusterIdx].categories[this.categoryIdx].sections[
          this.sectionIdx
        ].questions.length -
          1
      )
    },
    isFirstQuestion() {
      return (
        this.clusterIdx === 0 &&
        this.categoryIdx === 0 &&
        this.sectionIdx === 0 &&
        this.questionIdx === 0
      )
    },
    questionIdx() {
      return this.clusters[this.clusterIdx].categories[
        this.categoryIdx
      ].sections[this.sectionIdx].questions.findIndex(
        ques => ques.id === this.questionIdInt
      )
    },
    sectionIdx() {
      return this.clusters[this.clusterIdx].categories[
        this.categoryIdx
      ].sections.findIndex(sec => sec.id === this.sectionIdInt)
    },
    categoryIdx() {
      return this.clusters[this.clusterIdx].categories.findIndex(
        cat => cat.id === this.categoryIdInt
      )
    },
    showInput() {
      return isNil(this.questionData.parentQuestionId)
    },
    getAnswer() {
      return this.questionFromStore?.answer
    },
    showComments() {
      return !isNil(this.getAnswer?.id)
    },
    comments() {
      return this.getAnswer?.comments ?? []
    },
    inputModel: {
      get() {
        if (this.noStatement) {
          return '—'
        }
        return parseFloat(this.answerInputs.inputAnswer)
      },
      set(val) {
        this.answerInputs.inputAnswer = val.toString()
      },
    },
    saveButtonText() {
      if (this.isAnswerable) {
        return this.$t('general.save')
      }
      switch (this.answerInputs.state) {
        case EAnswerState.Answered:
          return this.$t('score.review-inReview')
        case EAnswerState.ReviewAccepted:
        case EAnswerState.EvaluationAccepted:
          return this.$t('score.review-accepted')
        case EAnswerState.Declined:
          return this.$t('score.review-declined')
        case EAnswerState.Verification:
          return this.$t('score.review-verification')
        default:
          return this.$t('general.save')
      }
    },
    percentageDeviation() {
      const tPrefix = 'score.question-virtualQuestionType'
      const deviation = round(this.answerInputs.resultDeviation, 1)
      switch (this.questionData.virtualQuestionType) {
        case EVirtQuestType.DW:
          if (this.answerInputs.resultDeviation === null) {
            return '—'
          }
          return this.$t(`${tPrefix}-dw-${deviation < 0 ? 'below' : 'above'}`, {
            value: deviation,
            unit: '%',
          })
        case EVirtQuestType.DK:
          if (this.object.country == 'Other Countries') {
            return this.$t(`${tPrefix}-crremNotAvailable`)
          } else if (this.answerInputs.resultDeviation === null) {
            return '—'
          }
          return this.$t(`${tPrefix}-dk-deviation`, {
            value: deviation,
            unit: '%',
          })
        case EVirtQuestType.Year:
          if (this.object.country == 'Other Countries') {
            return this.$t(`${tPrefix}-crremNotAvailable`)
          } else if (this.answerInputs.resultDeviation === null) {
            return '—'
          }
          return this.$t(`${tPrefix}-year-departure`, {
            numberOfYears: deviation,
            year: this.answerInputs.yearOfLeaving,
          })
        default:
          return '—'
      }
    },
    questionTypeInfo() {
      const type = this.questionData.type
      switch (type) {
        case EQuestionType.SingleChoice:
          return this.$t('score.question-singleChoice-info')
        case EQuestionType.MultipleChoice:
          return this.$t('score.question-multipleChoice-info')
        default:
          return ''
      }
    },
    score() {
      const { type, options } = this.questionData

      if (type === EQuestionType.VirtualQuestion) {
        if (this.answerInputs.reachedScore === null) {
          return 0
        }
        return this.answerInputs.reachedScore
      }

      const selectedOptions = this.answerInputs.multipleChoiceOption

      if (selectedOptions.length === 0) {
        return 0
      }

      if (type === EQuestionType.SingleChoice) {
        return options[selectedOptions[0]].score
      } else if (type === EQuestionType.MultipleChoice) {
        return sumBy(
          options.filter((_, idx) => selectedOptions.includes(idx)),
          'score'
        )
      }

      return 0
    },
    defaultingLangEntry() {
      return questionHelpers.defaultingLangEntry(this.questionData.translations)
    },

    exclusiveOptionIdx() {
      return this.questionData.options.reduce(function (a, opt, idx) {
        if (opt.isExclusive) a.push(idx)
        return a
      }, [])
    },

    currentExclusiveOptionIdx() {
      const idx = this.answerInputs.multipleChoiceOption.findIndex(r =>
        this.exclusiveOptionIdx.includes(r)
      )
      return this.answerInputs.multipleChoiceOption[idx]
    },

    isAnExclusiveMultipleChoiceAnswerSelected() {
      return (
        this.questionData.type === EQuestionType.MultipleChoice &&
        this.exclusiveOptionIdx.some(r =>
          this.answerInputs.multipleChoiceOption.includes(r)
        )
      )
    },

    changeLogButtonVariant() {
      return this.showChangeLog ? 'noBorderGreen' : 'noBorder'
    },

    questionFromStore() {
      // Needs to be used because 'questionData' is loaded before the answerChangeLog is fetched
      return this.getQuestion(this.retrievalConfig)
    },

    canSeeChangeLog() {
      return userHelpers.hasPermission([
        EUserRole.AccountAdministrator,
        EUserRole.ObjectAdministrator,
        EUserRole.ObjectDataCollector,
        EUserRole.ProspectiveBuyer,
      ])
    },

    hasChangeLog() {
      return this.questionFromStore.answerChangeLog?.length > 0
    },
  },
  methods: {
    ...mapMutations('questionnaire', ['setAnswer']),
    ...mapActions('questionnaire', ['addComment']),
    ...mapActions('toastMessage', ['showMessage']),
    onNoStatementSelect(noStatement) {
      this.noStatement = noStatement
      if (noStatement) {
        this.answerInputs.inputAnswer = ''
        this.answerInputs.resultDeviation = null
      } else {
        this.answerInputs.inputAnswer = 0
      }
    },
    defaultingLangOptions(option) {
      return questionHelpers.defaultingLangEntry(option.translations).text
    },
    populateQuestion() {
      const question = this.getQuestion(this.retrievalConfig)
      this.questionData = cloneDeep(
        pick(question, Object.keys(this.questionData))
      )
      // question.answer could be null at first
      this.answerInputs = cloneDeep(question.answer || this.emptyAnswer)

      this.noStatement = this.answerInputs.inputAnswer === ''
      if (this.noStatement) {
        // necessary b/c BE might return an old resultDeviation even though there is no answer ...
        this.answerInputs.resultDeviation = null
      }

      // First multiple choice option should always be exclusive
      if (
        this.questionData.type === EQuestionType.MultipleChoice &&
        this.questionData.options?.[0]
      ) {
        this.questionData.options[0].isExclusive = true
      }
    },
    fileUploadedCallback() {
      // if save button is disabled, dont do anything
      if (this.isAnswerable && !this.answerRequest.pending) {
        this.save()
      }
    },
    async nextQuestion() {
      let questionParams = { ...this.retrievalConfig }
      let clust = cloneDeep(this.clusters[this.clusterIdx])
      let cat = cloneDeep(clust.categories[this.categoryIdx])
      let sect = cloneDeep(cat.sections[this.sectionIdx])
      let ques = cloneDeep(sect.questions[this.questionIdx])

      if (this.isLastQuestion) {
        if (this.isLastSection) {
          if (this.isLastCategory) {
            clust = cloneDeep(this.clusters[this.clusterIdx + 1])
            cat = cloneDeep(clust.categories[0])
          } else {
            cat = cloneDeep(clust.categories[this.categoryIdx + 1])
          }
          sect = cloneDeep(cat.sections[0])
        } else {
          sect = cloneDeep(cat.sections[this.sectionIdx + 1])
        }
        ques = cloneDeep(sect.questions[0])
      } else {
        ques = cloneDeep(sect.questions[this.questionIdx + 1])
      }
      questionParams = {
        clusterNum: this.clusters.findIndex(c => c.id === clust.id),
        categoryId: cat.id,
        sectionId: sect.id,
        questionId: ques.id,
      }
      if (this.isAnswerable && !this.answerRequest.pending) {
        await this.save()
      }
      this.$router.push({
        name: 'answerQuestion',
        params: questionParams,
      })
    },
    async backQuestion() {
      let questionParams = { ...this.retrievalConfig }
      let clust = cloneDeep(this.clusters[this.clusterIdx])
      let cat = cloneDeep(clust.categories[this.categoryIdx])
      let sect = cloneDeep(cat.sections[this.sectionIdx])
      let ques = cloneDeep(sect.questions[this.questionIdx])

      if (this.questionIdx === 0) {
        if (this.sectionIdx === 0) {
          if (this.categoryIdx === 0) {
            clust = cloneDeep(this.clusters[this.clusterIdx - 1])
            cat = cloneDeep(clust.categories[clust.categories.length - 1])
          } else {
            cat = cloneDeep(clust.categories[this.categoryIdx - 1])
          }
          sect = cloneDeep(cat.sections[cat.sections.length - 1])
        } else {
          sect = cloneDeep(cat.sections[this.sectionIdx - 1])
        }
        ques = cloneDeep(sect.questions[sect.questions.length - 1])
      } else {
        ques = cloneDeep(sect.questions[this.questionIdx - 1])
      }
      questionParams = {
        clusterNum: this.clusters.findIndex(c => c.id === clust.id),
        categoryId: cat.id,
        sectionId: sect.id,
        questionId: ques.id,
      }
      if (this.isAnswerable && !this.answerRequest.pending) {
        await this.save()
      }
      this.$router.push({
        name: 'answerQuestion',
        params: questionParams,
      })
    },
    async save() {
      if (this.notValidNumber) {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-question-max-float-number-info',
        })
        return
      }
      this.debouncedStoreUpdate.flush()

      try {
        await this.answerRequest.request(
          this.retrievalConfig,
          this.assetId,
          this.object.isBuyerAsset
        )
        this.preventStoreUpdate = true
        // sync again with newly stored answer
        this.populateQuestion()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-question-answer-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-question-answer-error',
        })
      }
    },
    async postComment(text) {
      try {
        await this.commentRequest.request(this.retrievalConfig, {
          answerId: this.getAnswer.id,
          comment: text,
        })
        this.showMessage({
          type: 'success',
          translationKey: 'comments-post-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'comments-post-error',
        })
      }
    },
    isNonExclusiveMultipleChoiceAnswer(idx) {
      return (
        this.isAnExclusiveMultipleChoiceAnswerSelected &&
        idx !== this.currentExclusiveOptionIdx
      )
    },
    toggleChangeLogs() {
      this.showChangeLog = !this.showChangeLog
    },
    formatDate(date) {
      return generalHelper.formatDate(date)
    },
    changeLogUserName(answerChangeLog) {
      if (!answerChangeLog?.modifiedBy) {
        return `(${this.$t('general.deletedUser')})`
      }

      return `${answerChangeLog?.modifiedBy?.firstName} ${answerChangeLog?.modifiedBy?.lastName}`
    },
  },
  watch: {
    '$route.params.questionId'() {
      if (this.$route.name !== 'answerQuestion') {
        return
      }
      this.preventStoreUpdate = true
      // next tick, so it doesn't happen before route change
      this.$nextTick(this.populateQuestion)
    },
    'answerInputs': {
      handler(newInputs) {
        // prevent store save when inputs changed due to init or route change
        if (this.preventStoreUpdate) {
          this.preventStoreUpdate = false
        } else {
          this.debouncedStoreUpdate({
            retrievalConfig: this.retrievalConfig,
            answer: cloneDeep(this.answerInputs),
            noStatement: this.noStatement,
          })
          this.questionData.modified = true
          this.answerInputs.state = EAnswerState.Answered
        }
        // When an exclusive item is set (e.g. no information available), no other can be active
        if (
          this.isAnExclusiveMultipleChoiceAnswerSelected &&
          newInputs.multipleChoiceOption.length > 1
        ) {
          this.answerInputs.multipleChoiceOption = [
            this.currentExclusiveOptionIdx,
          ]
        }
      },
      deep: true,
    },
  },
  created() {
    this.debouncedStoreUpdate = debounce(this.setAnswer, 200)
    this.populateQuestion()
  },
}
</script>
