<template>
  <div
    class="mb-3 rounded-md border-solid border"
    :class="[borderColorClasses]"
  >
    <h2 class="flex justify-between text-lg font-semibold px-11 py-2">
      <span>{{ text }}</span>
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    borderColorClasses() {
      return this.color === 'orange'
        ? 'border-darkOrange bg-lightOrange text-darkOrange'
        : // Add more if required
          ''
    },
  },
}
</script>
