<template>
  <DynamicView :noPad="true" :breadcrumbs="breadcrumbs">
    <div class="layout-grid pb-6 mt-12 w-[98vw]">
      <h1 class="
          bg-white
          rounded-t-md
          text-lightBlack
          font-semibold
          text-[1.3rem]
          py-[1.25rem]
          px-[3rem]
        ">
        {{ object?.name || '&nbsp; ' }}
      </h1>

      <div>
        <Button class="min-w-[16.25rem] px-8 ml-12 mt-1" variant="tertiary" :disabled="!canStartNewQuestionniare"
          @click="openStartQuestionnaireDialog">
          {{ $t('assetManagement.startQuestionnaire') }}
        </Button>
      </div>

      <NavTreeObjectEdit :object="object" :questionnaires="questionnaireList" />

      <router-view class="bg-lightestGray p-4" :object="object" :questionnaires="questionnaires"
        :disallowEdit="disallowEdit" />
    </div>

    <StartQuestionnaireDialog v-model="startQuestionnaireDialog" v-bind="{ object, isFirstQuestionnaire }"
      @start="questionnaireCreated" />
  </DynamicView>
</template>

<script>
import { last } from 'lodash-es'
import { EAssetState, EUserRole, EQuestionnaireType } from '@/enums'
import { user } from '@/helpers'
import { Button } from '@/components/form'
import DynamicView from '@/components/layout/DynamicView'
import { NavTreeObjectEdit } from '@/components/navTree'
import { StartQuestionnaireDialog } from '@/components/dialog'

export default {
  components: {
    Button,
    DynamicView,
    NavTreeObjectEdit,
    StartQuestionnaireDialog,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    questionnaires: {
      type: Array,
      required: true,
    },
  },
  inject: ['newQuest'],
  data() {
    return {
      startQuestionnaireDialog: false,
    }
  },
  computed: {
    isResultRoute() {
      return this.$route.matched
        .slice(1)
        .find(part => part.name === 'questionnaireResults')
    },
    breadcrumbs() {
      const result = [
        {
          routeName: 'assetManagement',
          text: this.$t('pages.assetManagement'),
        },
        {
          routeName: 'objectManagement',
          text: this.$t('pages.objectManagement'),
        },
        {
          routeName: 'objectOverview',
          text: this.object.name || ' ',
        },
      ]

      const lastMatch = last(this.$route.matched)

      if (lastMatch.name === 'editObjectData') {
        result.push({
          text: this.$t('pages.editObjectInfo'),
        })
      } else if (lastMatch.name === 'questionnaireResultOverview') {
        const selectedQuestionnaire = this.questionnaires.find(
          ({ id }) => id.toString() === this.$route.params.questionnaireId
        )
        result.push(
          {
            text: selectedQuestionnaire.title,
            routeName: 'questionnaireResults',
          },
          {
            text: this.$t('assetManagement.resultOverview-tab'),
          }
        )
      } else if (lastMatch.name === 'questionnaireResultDetails') {
        const selectedQuestionnaire = this.questionnaires.find(
          ({ id }) => id.toString() === this.$route.params.questionnaireId
        )
        result.push(
          {
            text: selectedQuestionnaire.title,
            routeName: 'questionnaireResults',
          },
          {
            text: this.$t('assetManagement.resultDetail-tab'),
          }
        )
      }
      return result
    },
    questionnaireList() {
      return this.canAccessQuestionnaires ? this.questionnaires : []
    },
    userCanAnswer() {
      return user.hasPermission([EUserRole.ObjectDataCollector, EUserRole.ProspectiveBuyer])
    },
    canStartNewQuestionniare() {
      return (
        this.userCanAnswer &&
        this.object.canCreateQuestionnaire &&
        this.questionnaires.every(
          ({ assetQuestionnaireState: state }) => [EAssetState.Closed, EAssetState.Expired].includes(state)
        ) && this.IsactiveAndAnnualQuestionnaire
      )
    },
    isFirstQuestionnaire() {
      return this.questionnaires.length === 0
    },
    disallowEdit() {
      return this.$store.getters['auth/organisationIsFinancePartner']
    },
    activeQuestionnaire() {
      return this.$store.getters['questionnaire/getActiveQuestinnaire']
    },
    IsactiveAndAnnualQuestionnaire() {
      const isPointedtoActiveQuestionnaire = this.questionnaires.some(q => q.id === this.activeQuestionnaire.id)
      return (this.activeQuestionnaire.type === EQuestionnaireType.annual && !isPointedtoActiveQuestionnaire)
    }
  },
  methods: {
    canAccessQuestionnaires() {
      return user.hasPermission([EUserRole.ObjectDataCollector])
    },
    openStartQuestionnaireDialog() {
      this.startQuestionnaireDialog = true
    },
    questionnaireCreated(quest) {
      this.newQuest.value = quest
      this.$router.push({
        name: 'answerQuestionnaire',
        params: { questionnaireId: quest.id },
      })
    },
  },
  created() {
    this.$store.dispatch('questionnaire/getActiveQuestinnaire')

  }
}
</script>

<style scoped>
.layout-grid {
  display: grid;
  grid-template-columns: 363px 1fr;
}
</style>
