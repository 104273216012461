<template>
  <div>
    <div v-if="questionnaire?.modifiedBy">
      <h2 class="py-[15px] px-[38px] text-lightBlack">
        {{
          $t('assetManagement.questionnaire-lastChange', {
            date: formatDate(questionnaire?.modifiedDate),
            user:
              questionnaire?.modifiedBy?.firstName +
              ' ' +
              questionnaire?.modifiedBy?.lastName,
          })
        }}
      </h2>
    </div>
    <div class="flex text-[16px] font-semibold text-gray mt-3 mb-2">
      <router-link
        v-for="{ routeName, label } in tabs"
        :key="label"
        class="hover:text-primary min-w-[135px] py-[5px] px-[38px]"
        :class="$route.name === routeName ? 'text-primary' : 'text-gray'"
        :to="{ name: routeName }"
      >
        {{ label }}
      </router-link>
    </div>
    <QuestionnaireScoreInfo
      :assetId="assetId"
      :questionnaireId="questionnaireId"
      :isBuyerAsset="isBuyerAsset"
    />
  </div>
</template>

<script>
import { QuestionnaireScoreInfo } from '@/components/questionnaire/score'
import { useRequest } from '@/composition'
import { questionnaires as questionnairesApi } from '@/services/api'
import { mapActions } from 'vuex'
import { general } from '@/helpers'

export default {
  components: {
    QuestionnaireScoreInfo,
  },
  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },
  data() {
    return {
      questionnaire: null,
    }
  },
  setup(props) {
    const questionnaireRequest = useRequest(() =>
      questionnairesApi.getAssetQuest(props.assetId, props.questionnaireId,props.isBuyerAsset)
    )
    return {
      questionnaireRequest,
    }
  },
  computed: {
    tabs() {
      return [
        {
          routeName: 'metadataResultOverview',
          label: this.$t('assetManagement.resultOverview-tab'),
        },
        {
          routeName: 'metadataResultDetails',
          label: this.$t('assetManagement.resultDetail-tab'),
        },
      ]
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchQuestionnaire() {
      try {
        this.questionnaire = await this.questionnaireRequest.request()
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-list-fetch-error',
        })
        throw error
      }
    },
    formatDate(dateString) {
      return dateString != null ? general.formatDate(dateString) : ''
    },
  },
  watch: {
    questionnaireId: {
      handler() {
        this.fetchQuestionnaire()
      },
    },
  },
  created() {
    this.fetchQuestionnaire()
  },
}
</script>
