<template>
  <DynamicView :noPad="true" :breadcrumbs="breadcrumbs">
    <h1
      class="pt-[1.25rem] px-[3rem] text-[1.25rem] font-medium text-white"
    >{{ title || '&nbsp; ' }}</h1>
    <div class="flex mt-6 w-[98vw]">
      <div class="flex flex-col">
        <div class="nav-overflow pb-6 w-[363px] bg-[#DFE8E1]">
          <NavTreeQuestAnswers class="pb-6" />
        </div>
      </div>
      <div
        class="flex-grow flex flex-col bg-lightestGray p-6"
        :class="saveAllRequest.pending && 'cursor-wait'"
      >
        <div class="flex px-4">
          <ButtonWithPopup
            class="ml-auto"
            :class="canDoCluster1Action && !saveAllRequest.pending && 'group'"
            variant="noBorder"
            icon="copy"
            iconClasses="mr-1.5 group-hover:text-primary"
            :disabled="!canDoCluster1Action || saveAllRequest.pending"
            :items="cluster1Actions"
            @select="onCluster1ActionSelect"
          >
            <template #text>
              <span class="whitespace-nowrap group-hover:text-primary">Cluster I</span>
            </template>
            <template #items="{ item }">
              <HighlightText class="px-2" highlightClass="font-semibold">{{ item.text }}</HighlightText>
            </template>
          </ButtonWithPopup>

          <ExportButton
            class="ml-4"
            :disabled="
              canBeSaved || saveAllRequest.pending || organisationIsTestAccount
            "
            @onExportSelected="exportAssetQuestionnaire"
          />

          <Button
            class="px-4 ml-4"
            :class="
              canBeSaved && !saveAllRequest.pending && 'hover:text-primary'
            "
            variant="noBorder"
            icon="save"
            :disabled="!canBeSaved || saveAllRequest.pending"
            @click="saveAll"
          >{{ $t('general.saveAll') }}</Button>

          <Button
            class="px-4 ml-4"
            :class="
              canBeReviewed && !saveAllRequest.pending && 'hover:text-primary'
            "
            variant="noBorder"
            icon="assess"
            :disabled="
              !canBeReviewed ||
              saveAllRequest.pending ||
              organisationIsTestAccount
            "
            @click="auditQuestionnaireDialog = true"
          >{{ $t('score.record-startAudit') }}</Button>
        </div>

        <ClipLoader
          v-if="questFetch.pending"
          class="flex flex-center h-60"
          color="green"
          size="4rem"
        />
        <!-- object and disallowEdit props only relevant for object data view -->
        <router-view v-else :object="object" disallowEdit />
      </div>
    </div>

    <ClusterAnswersSaveDialog
      v-model="clusterAnswersSaveDialog"
      :object="object"
      :questionnaireTitle="title"
      :isBuyerAsset="isBuyerAsset"
    />
    <ClusterAnswersApplyDialog
      v-model="clusterAnswersApplyDialog"
      :object="object"
      :questionnaireTitle="title"
      :isBuyerAsset="isBuyerAsset"
    />
    <AuditQuestionnaireDialog
      v-model="auditQuestionnaireDialog"
      :id="assetId"
      :name="object.name"
      :isBuyerAsset="isBuyerAsset"
    />
    <ExportAssetQuestionnaireDialog
      v-model="exportAssetQuestionnaireDialog"
      v-bind="{ assetId, questionnaireId, exportFormat, isBuyerAsset }"
    />

    <LockedQuestionnaireDialog v-model="lockedQuestionnaireDialog" :lockedStatus="lockedStatus" />
  </DynamicView>
</template>

<script>
import { last } from 'lodash-es'
import { reactive } from 'vue'
import { useStore, mapGetters, mapActions } from 'vuex'
import {
  general,
  question as questionHelpers,
  user as userHelpers,
} from '@/helpers'
import { EAssetState, EUserRole } from '@/enums'
import { useRequest, useInterval } from '@/composition'
import DynamicView from '@/components/layout/DynamicView'
import { NavTreeQuestAnswers } from '@/components/navTree'
import { Button, ButtonWithPopup } from '@/components/form'
import { ExportButton } from '@/components/questionnaire'
import {
  AuditQuestionnaireDialog,
  ClusterAnswersSaveDialog,
  ClusterAnswersApplyDialog,
  ExportAssetQuestionnaireDialog,
  LockedQuestionnaireDialog,
} from '@/components/dialog'
import HighlightText from '@/components/HighlightText'
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  components: {
    DynamicView,
    Button,
    ButtonWithPopup,
    ExportButton,
    NavTreeQuestAnswers,
    AuditQuestionnaireDialog,
    ClusterAnswersSaveDialog,
    ClusterAnswersApplyDialog,
    ExportAssetQuestionnaireDialog,
    LockedQuestionnaireDialog,
    HighlightText,
    ClipLoader,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    questionnaireId: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    isBuyerAsset: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const questFetch = useRequest(() =>
      store.dispatch('questionnaire/fetchForAsset', {
        assetId: props.assetId,
        questionnaireId: props.questionnaireId,
        isBuyerAsset: props.isBuyerAsset,
      })
    )
    const saveAllRequest = useRequest(() =>
      store.dispatch('questionnaire/saveModifiedAnswers', {
        assetId: props.assetId,
        isBuyerAsset: props.isBuyerAsset,
      })
    )
    const lockRequest = useRequest(isLocked =>
      store.dispatch('questionnaire/lockQuestionnaire', {
        assetId: props.assetId,
        questionnaireId: props.questionnaireId,
        isLocked,
        isBuyerAsset: props.isBuyerAsset,
      })
    )
    const changeLogFetch = useRequest(() =>
      store.dispatch('questionnaire/fetchAnswerChangeLog', {
        assetId: props.assetId,
        questionnaireId: props.questionnaireId,
        isBuyerAsset: props.isBuyerAsset,
      })
    )
    const lockInterval = useInterval(() => lockRequest.request(true), '2m', {
      delayedStart: true,
      immediate: true,
    })

    const organisationIsTestAccount =
      store.getters['auth/organisationIsTestAccount']

    return reactive({
      questFetch,
      saveAllRequest,
      lockRequest,
      changeLogFetch,
      lockInterval,
      organisationIsTestAccount,
    })
  },
  data() {
    return {
      clusterAnswersSaveDialog: false,
      clusterAnswersApplyDialog: false,
      auditQuestionnaireDialog: false,
      exportAssetQuestionnaireDialog: false,
      exportFormat: null,
      lockedQuestionnaireDialog: false,
      lockIntervalId: undefined,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'clusters',
      'title',
      'assetState',
      'answeredQuestionsPercentage',
      'isModified',
      'canSetGroupDefaultAnswers',
      'lockedStatus',
    ]),
    ...mapGetters('auth', ['isExternalRole']),
    isModifiableQuestionnaire() {
      return (
        this.assetState === EAssetState.Active ||
        this.assetState === EAssetState.Declined ||
        this.assetState === EAssetState.Verification
      )
    },
    canBeAnswered() {
      return userHelpers.hasPermission([
        EUserRole.ObjectDataCollector,
        EUserRole.ProspectiveBuyer,
      ])
    },
    canBeSaved() {
      return (
        this.canBeAnswered && this.isModifiableQuestionnaire && this.isModified
      )
    },
    canBeReviewedByRole() {
      return (
        (userHelpers.hasPermission([EUserRole.ObjectDataCollector]) &&
          (this.assetState === EAssetState.Verification ||
            this.assetState === EAssetState.Declined)) ||
        userHelpers.hasPermission([
          EUserRole.ObjectAdministrator,
          EUserRole.ProspectiveBuyer,
        ])
      )
    },
    canBeReviewed() {
      return (
        this.canBeReviewedByRole &&
        this.isModifiableQuestionnaire &&
        this.answeredQuestionsPercentage === 100 &&
        !this.isModified
      )
    },
    canDoCluster1Action() {
      return (
        !this.isExternalRole &&
        (this.canSaveCluster1AnswersForGroup ||
          this.canApplyCluster1AnswersFromGroup) &&
        !this.isModified
      )
    },
    assetBelongsToUserOrgansiation() {
      return userHelpers.userOrganisationId() == this.object.assetGroup.organisationId
    },
    canSaveCluster1AnswersForGroup() {
      return (
        ((userHelpers.hasPermission([EUserRole.ProspectiveBuyer]) && this.assetBelongsToUserOrgansiation) ||
        userHelpers.hasPermission([EUserRole.ObjectAdministrator])) &&
        this.canSetGroupDefaultAnswers
      )
    },
    canApplyCluster1AnswersFromGroup() {
      return (
        ((userHelpers.hasPermission([EUserRole.ProspectiveBuyer]) && this.assetBelongsToUserOrgansiation) ||
        userHelpers.hasPermission([EUserRole.ObjectAdministrator])) &&
        this.assetState === EAssetState.Active
      )
    },
    cluster1Actions() {
      return [
        {
          key: 'save',
          text: this.$t('score.record-saveAnswersForGroup'),
          disabled: !this.canSaveCluster1AnswersForGroup,
        },
        {
          key: 'apply',
          text: this.$t('score.record-takeAnswersFromGroup'),
          disabled: !this.canApplyCluster1AnswersFromGroup,
        },
      ]
    },
    breadcrumbs() {
      const result = [
        {
          routeName: 'assetManagement',
          text: this.$t('pages.assetManagement'),
        },
        {
          routeName: 'objectManagement',
          text: this.$t('pages.objectManagement'),
        },
        {
          routeName: 'objectOverview',
          text: this.object.name || ' ',
        },
      ]

      const lastMatchedName = last(this.$route.matched).name

      if (lastMatchedName === 'showObjectData') {
        result.push({ text: this.$t('pages.editObjectInfo') })
        return result
      }

      if (
        ['metadataResultOverview', 'metadataResultDetails'].includes(
          lastMatchedName
        )
      ) {
        result.push({ text: this.$t('pages.metadata') })
        return result
      }

      result.push({
        text: `Cluster ${general.numeral(
          parseInt(this.$route.params.clusterNum) + 1
        )}`,
      })

      const cluster = this.clusters[this.$route.params.clusterNum]
      let category

      if (
        cluster &&
        [
          'answerClusterCategory',
          'answerClusterSection',
          'answerQuestion',
        ].includes(lastMatchedName)
      ) {
        category = cluster.categories.find(
          c => `${c.id}` === this.$route.params.categoryId
        )
        if (!category) {
          return result
        }
        result.push({
          text: questionHelpers.defaultingLangEntry(category.name).name,
        })
      }

      let section

      if (
        category &&
        ['answerClusterSection', 'answerQuestion'].includes(lastMatchedName)
      ) {
        section = category.sections.find(
          s => `${s.id}` === this.$route.params.sectionId
        )
        if (!section) {
          return result
        }
        result.push({
          text: questionHelpers.defaultingLangEntry(section.name).name,
        })
      }

      if (section && ['answerQuestion'].includes(lastMatchedName)) {
        const question = section.questions.find(
          s => `${s.id}` === this.$route.params.questionId
        )
        if (!question) {
          return result
        }
        const translation = questionHelpers.defaultingLangEntry(
          question.translations
        )
        result.push({ text: translation.title })
      }

      return result
    },
    canSeeChangeLog() {
      return (
        userHelpers.hasPermission([EUserRole.AccountAdministrator]) ||
        userHelpers.hasPermission([EUserRole.ObjectAdministrator]) ||
        userHelpers.hasPermission([EUserRole.ObjectDataCollector])
      )
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    onCluster1ActionSelect({ key }) {
      if (key === 'save') {
        this.clusterAnswersSaveDialog = true
      } else if (key === 'apply') {
        this.clusterAnswersApplyDialog = true
      }
    },
    async saveAll() {
      try {
        await this.saveAllRequest.request()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-question-answerAll-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-question-answerAll-error',
        })
      }
    },
    exportAssetQuestionnaire(exportFormat) {
      this.exportFormat = exportFormat
      this.exportAssetQuestionnaireDialog = true
    },
  },
  watch: {
    lockedStatus(newLockedStatus) {
      if (newLockedStatus) {
        this.lockedQuestionnaireDialog = true
        // When the questionnaire is locked, we don't need to retry. It is necessary to refresh the page to edit the data anyway.
        this.lockInterval.cancelInterval()
      }
    },
  },
  async created() {
    try {
      await this.questFetch.request()
      if (this.canSeeChangeLog) {
        await this.changeLogFetch.request()
      }
      if (this.canBeAnswered) {
        this.lockInterval.startInterval()
      }
    } catch {
      this.showMessage({
        type: 'error',
        translationKey: 'questionnaire-fetch-error',
      })
    }
  },
  async unmounted() {
    // Only disable lock, when we actually are the lockers
    if (!this.lockedStatus && this.canBeAnswered) {
      await this.lockRequest.request(false)
    }
  },
}
</script>

<style scoped>
.nav-overflow {
  flex-basis: 450px;
  flex-grow: 1;
  overflow-y: scroll;
}

.sidebar-link {
  @apply block px-[45px] hover:font-bold hover:text-primary;
}
</style>
