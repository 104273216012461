<template>
  <ClipLoader
    v-if="requestsPending"
    class="flex flex-center"
    color="green"
    size="4rem"
  />
  <router-view
    v-else-if="object && questionnaires"
    :object="object"
    :questionnaires="questionnaires"
  />
</template>

<script>
import { reactive } from 'vue'
import { mapActions } from 'vuex'
import { useRequest } from '@/composition'
import {
  objects as objectsApi,
  questionnaires as questionnairesApi,
} from '@/services/api'
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  components: {
    ClipLoader,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      object: null,
      questionnaires: null,
      newQuest: {
        value: null,
      },
      deletedQuestId: {
        value: null,
      },
    }
  },
  provide() {
    return {
      newQuest: this.newQuest,
      deletedQuestId: this.deletedQuestId,
    }
  },
  setup(props) {
    const objectRequest = useRequest(() =>
      objectsApi.getById(props.assetId, props.isBuyerAsset)
    )
    const questionnairesRequest = useRequest(() =>
      questionnairesApi.getAssetQuestList(props.assetId, props.isBuyerAsset)
    )
    return reactive({
      objectRequest,
      questionnairesRequest,
    })
  },
  computed: {
    requestsPending() {
      return this.objectRequest.pending || this.questionnairesRequest.pending
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async fetchObject() {
      try {
        this.object = await this.objectRequest.request()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-fetch-error',
        })
      }
    },
    async fetchQuestionnaires() {
      try {
        this.questionnaires = await this.questionnairesRequest.request()
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-list-fetch-error',
        })
      }
    },
  },
  watch: {
    'newQuest.value'(quest) {
      this.questionnaires.push(quest)
    },
    'deletedQuestId.value'(id) {
      this.questionnaires = this.questionnaires.filter(
        q => q.id !== parseInt(id)
      )
    },
    '$route'(to, from) {
      if (
        from.name === 'questionnaireResultOverview' &&
        to.name === 'editObjectData'
      ) {
        this.fetchQuestionnaires()
      }
    },
  },
  created() {
    this.fetchObject()
    this.fetchQuestionnaires()
  },
}
</script>
